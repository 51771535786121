/* ===========================
Collection promo tyle A section - component
=========================== */
.c-collection-promo-type-a-section {
    background-color: var(--white);
    padding: var(--space-8) 0;

    @include breakpoint(min-768) {
        padding: var(--space-16) 0;
    }

    @include breakpoint(min-1280) {
        padding: var(--space-20) 0;
    }
}

//page-wrap
.c-collection-promo-type-a-section .page-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include breakpoint(min-768) {
        width: 96%;
    }
}

.c-collection-promo-type-a-section__content {
    text-align: center;
    margin: 0 auto var(--space-8) auto;
    max-width: rem(640);
    width: 96%;

    @include breakpoint(min-768) {
        margin: 0 auto var(--space-16) auto;
        width: 100%;
    }

    @include breakpoint(min-1280) {
        margin: 0 auto var(--space-20) auto;
    }
}

.c-collection-promo-type-a-section__section-heading {
    display: block;
    text-align: center;
    color: var(--gray-40);
    font-size: var(--font-size-1);
    line-height: rem(18);
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin: 0 0 var(--space-2) 0;
}

.c-collection-promo-type-a-section__heading {
    display: block;
    text-align: center;
    font-size: var( --font-size-7);
    line-height: var( --line-height-5);
    margin-bottom: var(--space-2);
   
    @include breakpoint(min-768) {
        font-size: var(--font-size-9);
        line-height: rem(52);
        margin-bottom: var(--space-4);
    }
}

.c-collection-promo-type-a-section__desc {
    display: block;
    text-align: center;
    color: var(--gray-70);
    font-size: var(--font-size-3);
    line-height: rem(22);
    margin: 0 0 var(--space-8) 0;

    @include breakpoint(min-768) {
        font-size: var(--font-size-5);
        line-height: var( --line-height-4);
        margin: 0 0 var(--space-10) 0;
    }

    @include breakpoint(min-1280) {
        margin: 0 0 var(--space-16) 0;
    }
}

.c-collection-promo-type-a-section__product-content {
    display: flex;
    flex-direction: column;

    @include breakpoint(min-768) {
        flex-direction: row;
        gap: var(--space-6);
    }

    @include breakpoint(min-1280) {
        gap: var(--space-10);
    }
}

.c-collection-promo-type-a-section .cover {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: rem(440);
    display: inline-block;

    @include breakpoint(min-768) {
        height: auto;
        flex: 1 1 100%;
    }
}

.c-collection-promo-type-a-section .cover img {
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.c-collection-promo-type-a-section__product-list {
    width: 100%;
    display: grid;
    grid-gap: var(--space-4) var(--space-2);
    grid-template-columns: repeat(2, calc(50% - var(--space-1)));
    margin: 0;
    padding: 0;
    list-style-type: none;

    @include breakpoint(min-768) {
        grid-gap: var(--space-4);
        grid-template-columns: repeat(2, calc(50% - var(--space-2)));
    }
}