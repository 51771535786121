/* ===========================
Homepage
=========================== */
.hp-bestsellers {
	background-color: var(--white);
	padding: rem(40) 0 rem(60) 0;
	text-align: center;

	@include breakpoint(min-768) {
		padding: rem(60) 0 rem(80) 0;
	}

	@include breakpoint(min-1024) {
		padding: rem(80) 0 rem(100) 0;
	}

	@include breakpoint(min-1600) {
		padding: rem(100) 0 rem(120) 0;
	}
}

.hp-bestsellers .page-wrap h2 {
	text-align: center;
	color: var(--gray-50);
	font-size: var(--font-size-3);
	line-height: rem(16);
	text-transform: uppercase;
	margin: 0 auto rem(2) auto;

	@include breakpoint(min-640) {
		margin-bottom: rem(12);
	}
}

.hp-bestsellers .page-wrap strong {
	font-family: $font-stack-display;
	font-size: var(--font-size-7);
	line-height: rem(52);
	display: block;
	text-transform: none;
	color: var(--gray-90);
	font-weight: var(--font-weight-regular);
}

.hp-bestsellers .arrow-link {
	display: inline-block;

	@include breakpoint(min-768) {
		margin: 0 0 rem(20) 0;
	}
}

.hp-bestsellers .arrow-link .icon{
	top: rem(8);
}
	
.hp-bestsellers .products {
	margin-bottom: rem(20);
}

.o-hp-nav {
    background-color: var(--gold-20, #F0E6DB);
    padding: rem(32) 0 rem(40);

    @include breakpoint(min-960) {
        padding: rem(44) 0 rem(60);
    }
}

.o-hp-nav h2 {
    text-align: center;
}

.o-hp-nav__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.o-hp-nav__list-item {
    display: flex;
    flex-direction: column;
    flex: 0 0 calc(50% - 1rem);
    margin: rem(8) rem(8) rem(24) rem(8);
    align-items: center;
    max-width: rem(315);

    @include breakpoint(min-960) {
        margin: rem(16) rem(16) rem(32) rem(16);
        flex: 0 0 calc(25% - 2rem);
    }
}

.o-hp-nav__list-item img {
    display: block;
    max-width: 100%;
    height: auto;
    margin-bottom: rem(12);
}

.o-hp-nav__list-item a {
    text-align: center;
}