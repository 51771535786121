/* ===========================
Reviews section - component
=========================== */
.c-reviews-section {
    overflow: hidden;
    background-color: var(--gray-50);
    padding: var(--space-8) 0;

    @include breakpoint(min-768) {
        padding: var(--space-20) 0;
    }

    @include breakpoint(min-1280) {
        padding: var(--space-40) 0;
    }
}

.c-reviews-section picture {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--black);
}

.c-reviews-section img {
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: .6;

    @include breakpoint(min-768) {
        left: 0;
        right: auto;
        transform: translate(0, -50%);
    }

    @include breakpoint(min-1280) {
        opacity: 1;
    }
}

.c-reviews-section__content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: rem(640);
    gap: var(--space-4);

    @include breakpoint(min-768) {
        gap: var(--space-6);
    }
}

.c-reviews-section__heading {
    color: var(--white);
    font-size: var( --font-size-7);
    line-height: var( --line-height-5);
   
    @include breakpoint(min-768) {
        font-size: var(--font-size-9);
        line-height: rem(52);
    }
}

.c-reviews-section__logo {
    display: inline-block;

    &:focus {
        background-color: transparent;
    }
}

.c-reviews-section__logo .heureka-logo {
    width: rem(80);
    height: rem(80);
    border-radius: 100%;
    background-color: var(--white);

    @include breakpoint(min-768) {
        width: rem(100);
        height: rem(100);
    }
}

.c-reviews-section__review-list {
    margin: 0 0 var(--space-12) 0;
    padding: 0;
    list-style-type: none;
    display: grid;
    grid-gap: var(--space-10);
    grid-template-columns: repeat(1,1fr);
    width: 100%;
    max-width: rem(640);

    @include breakpoint(min-768) {
        grid-template-columns: repeat(2,1fr);
        margin: 0 0 var(--space-20) 0;
        grid-gap: var(--space-12) var(--space-6);
    }
}

.c-reviews-section__review-item {
    display: inline-flex;
    flex-direction: column;
    flex: 1 1 100%;
    gap: var(--space-2);

    @include breakpoint(min-768) {
        flex: 1 1 calc(50% - #{rem(24)});
    }
}

.c-reviews-section__review-star {
    display: flex;
    gap: var(--space-2);
}

.c-reviews-section__review-star .icon {
    width: rem(16);
    height: rem(16);
    fill: var(--sc-yellow);
}

.c-reviews-section__review-text {
    margin: 0;
    color: var(--white);
    font-size: var( --font-size-3);
    line-height: rem(22);
   
    @include breakpoint(min-768) {
        font-size: var(--font-size-4);
        line-height: var(--line-height-3);
    }
}

.c-reviews-section .btn-link {
    position: relative;
    color: var(--white);
    box-shadow: 0 rem(2) 0 0  var(--white);

    &:focus {
        background-color: transparent;
    }
}