/* ===========================
Collection promo tyle B section - component
=========================== */
.c-collection-promo-type-b-section {
    margin-bottom: var(--space-8);

    @include breakpoint(min-1280) {
        margin-bottom: var(--space-40);
    }
}

.c-collection-promo-type-b-section__wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: var(--gray-20);
    padding: var(--space-8) 0;

    @include breakpoint(min-768) {
        padding: var(--space-16) 0;
    }

    @include breakpoint(min-1280) {
        padding: var(--space-20) 0 rem(320) 0;
    }
}

.c-collection-promo-type-b-section__wrapper picture {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}

.c-collection-promo-type-b-section__wrapper img {
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.c-collection-promo-type-b-section__content {
    position: relative;
    text-align: center;
    margin: 0 auto;
    max-width: rem(640);
    width: 100%;
}

.c-collection-promo-type-b-section__section-heading {
    display: block;
    text-align: center;
    color: var(--white);
    font-size: var(--font-size-1);
    line-height: rem(18);
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin: 0 0 var(--space-2) 0;
}

.c-collection-promo-type-b-section__heading {
    display: block;
    text-align: center;
    color: var(--white);
    font-size: var( --font-size-7);
    line-height: var( --line-height-5);
    margin-bottom: var(--space-2);
   
    @include breakpoint(min-768) {
        font-size: var(--font-size-9);
        line-height: rem(52);
        margin-bottom: var(--space-4);
    }
}

.c-collection-promo-type-b-section__desc {
    display: block;
    text-align: center; 
    color: var(--white);
    font-size: var(--font-size-3);
    line-height: rem(22);
    margin: 0 0 var(--space-8) 0;

    @include breakpoint(min-768) {
        font-size: var(--font-size-5);
        line-height: var( --line-height-4);
        margin: 0 0 var(--space-10) 0;
    }

    @include breakpoint(min-1280) {
        margin: 0 0 var(--space-16) 0;
    }
}

// Custom CSS for carousel - Needs to be updated/unified in the future
.c-collection-promo-type-b-section .products {
    @include breakpoint(min-1280) {
        margin-top: calc(#{rem(160)} * -1);
    }
}